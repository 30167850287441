<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="width"
    viewBox="0 0 595.3 243.3"
    style="enable-background: new 0 0 595.3 243.3"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        :style="'fill: ' + background"
        d="M195.3,68.8L138,202.3h-32.2L48.5,68.8H81l41.5,99l42.8-99H195.3z"
      />
      <path
        class="st0"
        :style="'fill: ' + background"
        d="M318.5,145.5H213.8c1.8,9.8,6.6,17.6,14.4,23.4s17.4,8.6,28.9,8.6c14.7,0,26.8-4.8,36.2-14.5l16.8,19.2
		c-6,7.2-13.6,12.6-22.8,16.2c-9.2,3.7-19.5,5.5-31,5.5c-14.7,0-27.6-2.9-38.8-8.8c-11.2-5.8-19.8-14-25.9-24.4
		c-6.1-10.4-9.1-22.2-9.1-35.4c0-13,3-24.7,8.9-35.1C197.3,90,205.5,81.8,216,76c10.5-5.8,22.3-8.8,35.5-8.8c13,0,24.6,2.9,34.9,8.6
		s18.2,13.8,24,24.2c5.8,10.4,8.6,22.5,8.6,36.1C319,138.4,318.8,141.5,318.5,145.5z M226.1,101.1c-6.9,5.9-11.1,13.8-12.6,23.6
		h75.8c-1.3-9.7-5.4-17.5-12.2-23.5c-6.8-6-15.3-9-25.5-9C241.5,92.3,233.1,95.2,226.1,101.1z"
      />
      <path
        class="st0"
        :style="'fill: ' + background"
        d="M411.8,195c-3.7,3-8.1,5.2-13.4,6.8s-10.8,2.2-16.6,2.2c-14.7,0-26-3.8-34-11.5c-8-7.7-12-18.8-12-33.5V94.8
		h-22v-25h22V39.3H367v30.5h35.8v25H367v63.5c0,6.5,1.6,11.5,4.8,14.9c3.2,3.4,7.8,5.1,13.8,5.1c7,0,12.8-1.8,17.5-5.5L411.8,195z"
      />
      <path
        class="st0"
        :style="'fill: ' + background"
        d="M439,195.3c-10.8-5.8-19.3-14-25.4-24.4c-6.1-10.4-9.1-22.2-9.1-35.4c0-13.2,3-24.9,9.1-35.3
		c6.1-10.3,14.5-18.4,25.4-24.2c10.8-5.8,23-8.8,36.5-8.8c13.7,0,25.9,2.9,36.8,8.8c10.8,5.8,19.3,13.9,25.4,24.2
		c6.1,10.3,9.1,22.1,9.1,35.3c0,13.2-3,25-9.1,35.4c-6.1,10.4-14.5,18.5-25.4,24.4c-10.8,5.8-23.1,8.8-36.8,8.8
		C462,204,449.8,201.1,439,195.3z M504,165.8c7.5-7.7,11.2-17.8,11.2-30.2s-3.8-22.6-11.2-30.3c-7.5-7.7-17-11.5-28.5-11.5
		s-21,3.8-28.4,11.5c-7.4,7.7-11.1,17.8-11.1,30.3s3.7,22.6,11.1,30.2c7.4,7.7,16.9,11.5,28.4,11.5S496.5,173.4,504,165.8z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Logo",
  props: ["width", "background"],
};
</script>

<style>
</style>